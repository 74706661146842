import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { ApiUrlConstants } from "../constants/api-url.constants";
import { District } from "../models/district";
import { Grade } from "../models/grade";
import { Zone } from "../models/zone";
import { Student } from "../models/student-base";
import { RestService } from "./rest.service";
import { UtilityService } from "./utility.service";
import { School } from "../models/school";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class ApiDataService extends RestService {
  // constructor to inject base url from local storage
  constructor(injector: Injector) {
    super(injector);
  }
  addSubstitution(studentId: number, tutorId: number): Observable<any> {
    const data = {
      studentId,
      tutorId,
      is_primary: 0,
      is_substitute: 1,
    };
    return this.post(ApiUrlConstants.ADD_SUBSTITUTION, data);
  }
  deleteConsentForm(fileId: number): Observable<any> {
    // Construct the URL dynamically using the fileId as a query parameter
    return this.delete(`${ApiUrlConstants.DELETE_STUDENT_FILES}/${fileId}`);
  }

  inviteTeamUserToMiro(userEmail: string): Observable<any> {
    const data = {
      userEmail: userEmail,
    };
    return this.post(ApiUrlConstants.ADD_TEAMUSERS, data);
  }
  getStudentforSubstitute(searchQuery: string): Observable<any> {
    // Use the searchQuery parameter to construct the URL for the HTTP request
    return this.get(
      `${ApiUrlConstants.GET_SUB_STUDENTS}?search=${searchQuery}`
    );
  }
  getTutorforSubstitute(searchQuery: string): Observable<any> {
    // Use the searchQuery parameter to construct the URL for the HTTP request
    return this.get(`${ApiUrlConstants.GET_SUB_TUTORS}?search=${searchQuery}`);
  }

  deleteSubstitution(id: number): Observable<any> {
    return this.delete(`${ApiUrlConstants.DELETE_SUBSTITUTION}/${id}`);
  }

  getSubstitutions(): Observable<any> {
    return this.get(ApiUrlConstants.GET_ALL_SUBSTITUTION);
  }

  getUserInfo(): Observable<any> {
    return this.get(ApiUrlConstants.GET_USER_INFO);
  }

  getAllGrades(): Observable<Grade[]> {
    return this.get(ApiUrlConstants.GRADE_LIST);
  }

  getAllZones(): Observable<Zone[]> {
    return this.get(ApiUrlConstants.ZONE_LIST);
  }

  getAllStudents(student): Observable<any> {
    return this.post(ApiUrlConstants.STUDENT_LIST, student);
  }
  getAllAssociatedStudents(id, student): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.ASSOCIATED_STUDENT_LIST, [
        { text: "@id", data: id },
      ]),
      student
    );
  }
  getAllPreferences(): Observable<any> {
    return this.get(ApiUrlConstants.GET_ALL_PREFERENCES);
  }

  bulkActionStudents(bulklActionBody): Observable<any> {
    return this.post(ApiUrlConstants.BULK_ACTION, bulklActionBody);
  }

  getVolunteerList(tutors): Observable<any> {
    return this.post(ApiUrlConstants.GET_VOLUNTEERS, tutors);
  }
  getVolunteerNames(): Observable<any> {
    return this.get(ApiUrlConstants.GET_VOLUNTEER_LIST);
  }

  getVolunteer(volunteerId): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.VOLUNTEER_DETAILS, [
        { text: "@id", data: volunteerId },
      ])
    );
  }
  tutorsAssociatedWithSchool(tutorId): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.TUTOR_ASSOCIATED_SCHOOLS, [
        { text: "@id", data: tutorId },
      ])
    );
  }
  getAssociatedSchool(tutorId): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.GET_ASSOCIATED_SCHOOLS, [
        { text: "@id", data: tutorId },
      ])
    );
  }
  studentsAssociatedWithSchool(schoolId): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.STUDENT_ASSOCIATED_SCHOOLS, [
        { text: "@id", data: schoolId },
      ])
    );
  }
  createVolunteer(volunteer): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_TUTOR, volunteer);
  }
  updateVolunteer(volunteerId, volunteer): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.UPDATE_TUTOR, [
        { text: "@id", data: volunteerId },
      ]),
      volunteer
    );
  }

  setBoardData(
    schoolId?: number,
    studentId?: number,
    lessnId?: number,
    tutorId?: number
  ): Observable<any> {
    let params = new HttpParams();
    if (schoolId) params = params.append("schoolId", schoolId.toString());
    if (studentId) params = params.append("studentId", studentId.toString());
    if (lessnId) params = params.append("lessonId", lessnId.toString());
    if (tutorId) params = params.append("tutorId", tutorId.toString());
    return this.get(ApiUrlConstants.GET_BOARD_COPY, { params: params });
  }

  getStudent(studentId): Observable<Student> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.STUDENT_DETAILS, [
        { text: "@id", data: studentId },
      ])
    );
  }
  getAllCommitments(): Observable<any> {
    return this.get(ApiUrlConstants.COMMITMENT_LEVELS);
  }
  getAllRoles(): Observable<any> {
    return this.get(ApiUrlConstants.GET_ALL_ROLES);
  }
  getSchoolList(): Observable<any> {
    return this.get(ApiUrlConstants.GET_SCHOOL_NAMES);
  }

  getSchools(schools): Observable<any> {
    return this.post(ApiUrlConstants.GET_SCHOOLS, schools);
  }

  getSchool(schoolId): Observable<Student> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.SCHOOL_DETAILS, [
        { text: "@id", data: schoolId },
      ])
    );
  }
  updateTutorSlot(schools): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_ASSOCIATED_TUTORS_COUNT, schools);
  }
  createSchool(school): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_SCHOOL, school);
  }
  updateSchool(school): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_SCHOOL, school);
  }

  deleteSchool(id, school): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.DELETE_SCHOOL, [
        { text: "@id", data: id },
      ]),
      school
    );
  }
  createStudent(student): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_STUDENT, student);
  }
  updateStudent(studentId, student): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.UPDATE_STUDENT, [
        { text: "@id", data: studentId },
      ]),
      student
    );
  }

  getTutorsToAssociate(body): Observable<any> {
    return this.post(ApiUrlConstants.GET_TUTORS_TO_ASSOCIATE, body);
  }
  associateTutor(body): Observable<any> {
    return this.post(ApiUrlConstants.ASSOCIATE_TUTORS, body);
  }
  removeAssociation(body): Observable<any> {
    return this.post(ApiUrlConstants.REMOVE_ASSOCIATION, body);
  }
  changePrimaryAssoc(body): Observable<any> {
    return this.post(ApiUrlConstants.CHANGE_PRIMARY_ASSOC, body);
  }

  getAllDistricts(): Observable<any> {
    return this.get(ApiUrlConstants.DISTRICT_LIST);
  }

  getAllStates(): Observable<any> {
    return this.get(ApiUrlConstants.STATES_LIST);
  }

  getDistrictList(district): Observable<any> {
    return this.post(ApiUrlConstants.DISTRICT, district);
  }
  getDistrict(districtId): Observable<District> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.DISTRICT_DETAILS, [
        { text: "@id", data: districtId },
      ])
    );
  }
  createDistrict(district): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_DISTRICT, district);
  }
  updateDistrict(district): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_DISTRICT, district);
  }

  getAppLinks(link): Observable<any> {
    return this.post(ApiUrlConstants.GET_APP_LINKS, link);
  }
  getAllLinks(): Observable<any> {
    return this.get(ApiUrlConstants.GET_APP_LINKS);
  }
  deleteLink(id, link): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.DELETE_LINK, [
        { text: "@id", data: id },
      ]),
      link
    );
  }
  getLink(id): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.GET_LINK, [
        { text: "@id", data: id },
      ])
    );
  }
  createLink(eventSignup): Observable<any> {
    return this.post(ApiUrlConstants.CREATE_LINK, eventSignup);
  }
  updateLink(eventSignup): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_LINK, eventSignup);
  }
  importStudents(studentForm): Observable<any> {
    const body = new FormData();
    body.append("file", studentForm.file);
    body.append("school", studentForm.school);
    return this.post(ApiUrlConstants.IMPORT_STUDENTS, body);
  }
  importTutor(studentForm, schools): Observable<any> {
    const body = new FormData();
    body.append("file", studentForm.file);
    let school = [];
    schools.forEach((value: School) => {
      school.push(value.id);
    });
    body.append("school", school.toString());
    return this.post(ApiUrlConstants.IMPORT_TUTOR, body);
  }
  importScores(studentForm): Observable<any> {
    const body = new FormData();
    body.append("scores", studentForm.file);
    body.append("school", studentForm.school);
    return this.post(ApiUrlConstants.IMPORT_SCORES, body);
  }

  updateAbsence(absence): Observable<any> {
    return this.post(ApiUrlConstants.UPDATE_ABSENCE, absence);
  }

  deleteAbsence(absenceId): Observable<any> {
    return this.get(
      UtilityService.replaceText(ApiUrlConstants.DELETE_ABSENCE, [
        { text: "@absenceId", data: absenceId },
      ])
    );
  }

  updateNewProgress(progress): Observable<any> {
    const url = !progress.control_group
      ? ApiUrlConstants.ADD_NEW_PROGRESS
      : ApiUrlConstants.CREATE_ABSENCE;
    return this.post(url, progress);
  }

  updateScores(score): Observable<any> {
    const url = score.id
      ? ApiUrlConstants.UPDATE_SCORE
      : ApiUrlConstants.CREATE_SCORE;
    return this.post(url, score);
  }

  // new api to deactivate
  setTutorActivitys(tutorId, tutor): Observable<any> {
    return this.put(
      UtilityService.replaceText(ApiUrlConstants.ISACTIVE_TUTOR, [
        { text: "@id", data: tutorId },
      ]),
      tutor
    );
  }

  getProgressByLesson(progress): Observable<any> {
    return this.post(ApiUrlConstants.GET_PROGRESS_BY_LESSON, progress);
  }

  getAllReason(): Observable<any> {
    return this.get(ApiUrlConstants.GET_ALL_REASON);
  }

  editProgress(progress): Observable<any> {
    return this.post(ApiUrlConstants.EDIT_PROGRESS, progress);
  }
}
