import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";



@Component({
  selector: "app-files-delete-confirmation",
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      {{ data.description }}
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancel()">No</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </div>
  `,
  styleUrls: ["./files-delete-confirmation.component.css"],
})
export class FilesDeleteConfirmationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FilesDeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
