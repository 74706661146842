import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import {
  APP_BASE_HREF,
  LocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StudentListComponent } from "./feature-modules/student-list/student-list.component";
import { PrimaryDashboardComponent } from "./feature-modules/primary-dashboard/primary-dashboard.component";
import { HeaderComponent } from "./shared/components/header/header.component";
import { SharedModule } from "./shared/shared.module";
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthorizationService } from "./shared/services/authorization.service";
import { SharedDialogService } from "./shared/services/shared-dialog.service";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { ToastrModule } from "ngx-toastr";
import { TokenInterceptor } from "./shared/services/token-interceptor.service";
import { DistrictsComponent } from "./feature-modules/districts/districts.component";
import { DistrictDetailsComponent } from "./feature-modules/districts/district-details/district-details.component";
import { LessonModule } from "./feature-modules/lessons/lessons.module";
import { SchoolModule } from "./feature-modules/schools/schools.module";
import { ReportsModule } from "./feature-modules/reports/reports.module";
import { TutorModule } from "./feature-modules/tutor-list/tutor.module";
import { DeactivateTutorComponent } from "./shared/modal-dialogs/deactivate-tutor/deactivate-tutor.component";
import { UpdateProgressAction } from "./shared/modal-dialogs/update-progress/update-progress.component";
import { AngularMaterialModule } from "./shared/angular-material.module";
import { InactiveStudentListComponent } from "./feature-modules/student-list/inactive-student-list/inactive-student-list.component";
import { FormLinkModule } from "./feature-modules/forms/forms.module";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./feature-modules/change-password/change-password.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { StudenFilterService } from "./studen-filter.service";
import { TutorDashboardComponent } from "./feature-modules/tutor-dashboard/tutor-dashboard.component";
import { UpdateProgressComponent } from "./feature-modules/student-list/student-details/progress/update-progress/update-progress.component";
import { TutorDashboardAbsentComponent } from "./feature-modules/tutor-dashboard-absent/tutor-dashboard-absent.component";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "angularx-social-login";
import { TutorPageComponent } from "./feature-modules/tutor-page/tutor-page.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SubstituteTutorComponent } from "./feature-modules/substitute-tutor/substitute-tutor.component";
import { ConfirmDialogComponent } from "./feature-modules/confirm-dialog/confirm-dialog.component";
import { FilesDeleteConfirmationComponent } from './feature-modules/files-delete-confirmation/files-delete-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    StudentListComponent,
    PrimaryDashboardComponent,
    HeaderComponent,
    SidebarComponent,
    DistrictsComponent,
    DistrictDetailsComponent,
    DeactivateTutorComponent,
    InactiveStudentListComponent,
    ChangePasswordComponent,
    TutorDashboardComponent,
    TutorDashboardAbsentComponent,
    UpdateProgressAction,
    TutorPageComponent,
    SubstituteTutorComponent,
    ConfirmDialogComponent,
    FilesDeleteConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    LessonModule,
    ReportsModule,
    SchoolModule,
    TutorModule,
    AngularMaterialModule,
    FormLinkModule,
    ToastrModule.forRoot(),
    MatButtonToggleModule,
    MatPasswordStrengthModule,
    MatAutocompleteModule,
    SocialLoginModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    AuthorizationService,
    SharedDialogService,
    AuthGuardService,
    StudenFilterService,
    { provide: APP_BASE_HREF, useValue: "/" },

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "351161572868-jsbvt03cb1h9n2640k7ejg0n2i0g4p2q.apps.googleusercontent.com",
              { plugin_name: "chat" }
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
