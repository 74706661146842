export class StudentConstants {
  public static GRADES = ["All Grades", "2nd Grade", "3rd Grade", "5th Grade"];
  public static NETWORK_ERR = "Network response was not found";
  public static FILE_DOWNLOAD_ERR = "There was an error downloading the file";
  public static FILE_TYPE_ERROR = "Please select a valid PDF file";
  public static PDF_FILE_TYPE = "application/pdf";
  public static DEL_MODAL_TITLE = "Delete";
  public static DEL_FILE_FAILED_ALERT =
    "Failed to delete the selected file. Please try again.";
  public static FILE_TYPE_ERR = "The selected file type is unknown";
  public static DEL_MODAL_DEC = "Are you sure you want to delete this file..?";
  public static EXISTING_GRADES = ["2nd", "3rd", "5th"];
  public static BULK_ACTIONS_ACTIVE = [
    "Advance Grade",
    "Revert Grade",
    "Deactivate",
    "Edit Teacher",
    "Add Notes",
  ];
  public static TUTOR_BULK_ACTIONS_ACTIVE = ["Edit Teacher", "Add Notes"];
  public static BULK_ACTIONS_INACTIVE = ["Activate", "Add Notes"];
  public static TUTOR_BULK_ACTIONS_INACTIVE = ["Add Notes"];
  public static STUDENT_ACTIONS = [
    "Import Students",
    "Create Student",
    "Import Scores",
  ];
  public static TUTOR_ACTION = ["Import Tutor", "Create Tutor"];
  public static ALL_SCHOOLS = ["All Schools"];
  public static ALL_GRADES = ["All Grades"];
  public static INACTIVE_STATUS = [
    "Graduated",
    "Scored Out",
    "Moved",
    "Removed",
  ];
  public static MAT_TAB_LABELS = ["H.U.G Students", "Control Group"];
  public static STUDENT_BLANK_TEMPLATE_LINK =
    "https://s3.amazonaws.com/hug-dev/hug-dev/student_import_blank.xlsx";
  public static SCORE_BLANK_TEMPLATE_LINK =
    "https://s3.amazonaws.com/hug-dev/hug-dev/DRA_import_template.xlsx";
  public static PAIRING_GROUP = [
    { display: "0", value: 0 },
    { display: "*", value: 1 },
    { display: "**", value: 2 },
    { display: "***", value: 3 },
    { display: "****", value: 4 },
  ];
  public static SKILL_LEVELS = [
    { color: "Red", value: 1 },
    { color: "Orange", value: 2 },
    { color: "Yellow", value: 3 },
    { color: "Light Blue", value: 4 },
    { color: "Green", value: 5 },
  ];
  public static ROLES = [
    { role: "Admin", value: 1 },
    { role: "Specialist", value: 2 },
    { role: "Tutor", value: 3 },
  ];
  public static TUTOR_ROLE_ID = 3;
  public static get YEARREGEX(): RegExp {
    return /^\d{4}\/\d{4}$/;
  }
}